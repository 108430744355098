"use client"

import errorConfig from "~/config/not-found.json"
import { geti18nLocale } from "~/lib/i18n/utils/get-processed-locale"
import useLocale from "~/hooks/useLocale"
import Error from "~/components/ui/Error"

export default function NotFoundPage() {
  const locale = useLocale()
  const i18nLocale = geti18nLocale(locale)
  const DATA = errorConfig[i18nLocale]

  return <Error {...DATA} />
}
